import React from 'react';
import { Helmet } from 'react-helmet';

const StateCard = ({ state }) => {
    return (
        <div className="card mx-auto" style={{ maxWidth: '500px', border: 'none', borderRadius: '0px' }}>
            <Helmet>
                <title>{`${state.name} - Random US State`}</title>
            </Helmet>
            <img src={state.flag} className="card-img-top" alt={`${state.name} flag`} style={{ borderRadius: '0px' }}/>
            <div className="card-body">
                <h5 className="card-title">{state.name} ({state.abbreviation})</h5>
                <p className="card-text"><strong>Capital:</strong> {state.capital}</p>
                <p className="card-text"><strong>Population:</strong> {state.population}</p>
                <p className="card-text"><strong>Area:</strong> {state.area}</p>
                <p className="card-text"><strong>Motto:</strong> {state.motto}</p>
                <p className="card-text"><strong>Nickname:</strong> {state.nickname}</p>
                <p className="card-text"><strong>State Flower:</strong> {state.stateFlower}</p>
                <p className="card-text"><strong>State Bird:</strong> {state.stateBird}</p>
            </div>
        </div>
    );
};

export default StateCard;
