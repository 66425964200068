export const stateInfo = {
    alabama: {
        name: "Alabama",
        flag: "/flags/al.svg",
        abbreviation: "AL",
        population: "5.1 Million (24th)",
        capital: "Montgomery",
        area: "52,419 sq mi (27th)",
        motto: "Audemus jura nostra defendere (We dare defend our rights)",
        nickname: "The Yellowhammer State",
        stateFlower: "Camellia",
        stateBird: "Yellowhammer"
    },
    alaska: {
        name: "Alaska",
        flag: "/flags/ak.svg",
        abbreviation: "AK",
        population: "0.7 Million (48th)",
        capital: "Juneau",
        area: "663,267 sq mi (1st)",
        motto: "North to the Future",
        nickname: "The Last Frontier",
        stateFlower: "Forget-me-not",
        stateBird: "Willow Ptarmigan"
    },
    arizona: {
        name: "Arizona",
        flag: "/flags/az.svg",
        abbreviation: "AZ",
        population: "7.3 Million (14th)",
        capital: "Phoenix",
        area: "113,990 sq mi (6th)",
        motto: "Ditat Deus (God Enriches)",
        nickname: "The Grand Canyon State",
        stateFlower: "Saguaro Cactus Blossom",
        stateBird: "Cactus Wren"
    },
    arkansas: {
        name: "Arkansas",
        flag: "/flags/ar.svg",
        abbreviation: "AR",
        population: "3.0 Million (33rd)",
        capital: "Little Rock",
        area: "53,179 sq mi (29th)",
        motto: "Regnat populus (The People Rule)",
        nickname: "The Natural State",
        stateFlower: "Apple Blossom",
        stateBird: "Mockingbird"
    },
    california: {
        name: "California",
        flag: "/flags/ca.svg",
        abbreviation: "CA",
        population: "39.2 Million (1st)",
        capital: "Sacramento",
        area: "163,696 sq mi (3rd)",
        motto: "Eureka (I have found it)",
        nickname: "The Golden State",
        stateFlower: "California Poppy",
        stateBird: "California Quail"
    },
    colorado: {
        name: "Colorado",
        flag: "/flags/co.svg",
        abbreviation: "CO",
        population: "5.8 Million (21st)",
        capital: "Denver",
        area: "104,094 sq mi (8th)",
        motto: "Nil sine numine (Nothing without providence)",
        nickname: "The Centennial State",
        stateFlower: "Rocky Mountain Columbine",
        stateBird: "Lark Bunting"
    },
    connecticut: {
        name: "Connecticut",
        flag: "/flags/ct.svg",
        abbreviation: "CT",
        population: "3.6 Million (29th)",
        capital: "Hartford",
        area: "5,567 sq mi (48th)",
        motto: "Qui transtulit sustinet (He who transplanted sustains)",
        nickname: "The Constitution State",
        stateFlower: "Mountain Laurel",
        stateBird: "American Robin"
    },
    delaware: {
        name: "Delaware",
        flag: "/flags/de.svg",
        abbreviation: "DE",
        population: "1.0 Million (45th)",
        capital: "Dover",
        area: "2,489 sq mi (49th)",
        motto: "Liberty and Independence",
        nickname: "The First State",
        stateFlower: "Peach Blossom",
        stateBird: "Delaware Blue Hen"
    },
    florida: {
        name: "Florida",
        flag: "/flags/fl.svg",
        abbreviation: "FL",
        population: "22.2 Million (3rd)",
        capital: "Tallahassee",
        area: "65,758 sq mi (22nd)",
        motto: "In God We Trust",
        nickname: "The Sunshine State",
        stateFlower: "Orange Blossom",
        stateBird: "Northern Mockingbird"
    },
    georgia: {
        name: "Georgia",
        flag: "/flags/ga.svg",
        abbreviation: "GA",
        population: "10.7 Million (8th)",
        capital: "Atlanta",
        area: "59,425 sq mi (24th)",
        motto: "Wisdom, Justice, Moderation",
        nickname: "The Peach State",
        stateFlower: "Cherokee Rose",
        stateBird: "Brown Thrasher"
    },
    hawaii: {
        name: "Hawaii",
        flag: "/flags/hi.svg",
        abbreviation: "HI",
        population: "1.4 Million (40th)",
        capital: "Honolulu",
        area: "10,931 sq mi (43rd)",
        motto: "Ua mau ke ea o ka ʻāina i ka pono (The life of the land is perpetuated in righteousness)",
        nickname: "The Aloha State",
        stateFlower: "Hibiscus",
        stateBird: "Nene (Hawaiian Goose)"
    },
    idaho: {
        name: "Idaho",
        flag: "/flags/id.svg",
        abbreviation: "ID",
        population: "1.9 Million (38th)",
        capital: "Boise",
        area: "83,569 sq mi (14th)",
        motto: "Esto perpetua (Let it be perpetual)",
        nickname: "The Gem State",
        stateFlower: "Syringa",
        stateBird: "Mountain Bluebird"
    },
    illinois: {
        name: "Illinois",
        flag: "/flags/il.svg",
        abbreviation: "IL",
        population: "12.6 Million (6th)",
        capital: "Springfield",
        area: "57,914 sq mi (25th)",
        motto: "State Sovereignty, National Union",
        nickname: "The Prairie State",
        stateFlower: "Violet",
        stateBird: "Northern Cardinal"
    },
    indiana: {
        name: "Indiana",
        flag: "/flags/in.svg",
        abbreviation: "IN",
        population: "6.8 Million (17th)",
        capital: "Indianapolis",
        area: "36,420 sq mi (38th)",
        motto: "The Crossroads of America",
        nickname: "The Hoosier State",
        stateFlower: "Peony",
        stateBird: "Northern Cardinal"
    },
    iowa: {
        name: "Iowa",
        flag: "/flags/ia.svg",
        abbreviation: "IA",
        population: "3.2 Million (31st)",
        capital: "Des Moines",
        area: "56,273 sq mi (26th)",
        motto: "Our liberties we prize and our rights we will maintain",
        nickname: "The Hawkeye State",
        stateFlower: "Wild Prairie Rose",
        stateBird: "Eastern Goldfinch"
    },
    kansas: {
        name: "Kansas",
        flag: "/flags/ks.svg",
        abbreviation: "KS",
        population: "2.9 Million (34th)",
        capital: "Topeka",
        area: "82,278 sq mi (15th)",
        motto: "Ad astra per aspera (To the stars through difficulties)",
        nickname: "The Sunflower State",
        stateFlower: "Sunflower",
        stateBird: "Western Meadowlark"
    },
    kentucky: {
        name: "Kentucky",
        flag: "/flags/ky.svg",
        abbreviation: "KY",
        population: "4.5 Million (26th)",
        capital: "Frankfort",
        area: "40,408 sq mi (37th)",
        motto: "United we stand, divided we fall",
        nickname: "The Bluegrass State",
        stateFlower: "Goldenrod",
        stateBird: "Northern Cardinal"
    },
    louisiana: {
        name: "Louisiana",
        flag: "/flags/la.svg",
        abbreviation: "LA",
        population: "4.6 Million (25th)",
        capital: "Baton Rouge",
        area: "52,378 sq mi (31st)",
        motto: "Union, Justice, Confidence",
        nickname: "The Pelican State",
        stateFlower: "Magnolia",
        stateBird: "Brown Pelican"
    },
    maine: {
        name: "Maine",
        flag: "/flags/me.svg",
        abbreviation: "ME",
        population: "1.4 Million (42nd)",
        capital: "Augusta",
        area: "35,385 sq mi (39th)",
        motto: "Dirigo (I lead)",
        nickname: "The Pine Tree State",
        stateFlower: "White Pine Cone and Tassel",
        stateBird: "Chickadee"
    },
    maryland: {
        name: "Maryland",
        flag: "/flags/md.svg",
        abbreviation: "MD",
        population: "6.2 Million (18th)",
        capital: "Annapolis",
        area: "12,407 sq mi (42nd)",
        motto: "Fatti maschii, parole femine (Manly deeds, womanly words)",
        nickname: "The Old Line State",
        stateFlower: "Black-eyed Susan",
        stateBird: "Baltimore Oriole"
    },
    massachusetts: {
        name: "Massachusetts",
        flag: "/flags/ma.svg",
        abbreviation: "MA",
        population: "7.0 Million (15th)",
        capital: "Boston",
        area: "10,565 sq mi (44th)",
        motto: "Ense petit placidam sub libertate quietem (By the sword we seek peace, but peace only under liberty)",
        nickname: "The Bay State",
        stateFlower: "Mayflower",
        stateBird: "Black-capped Chickadee"
    },
    michigan: {
        name: "Michigan",
        flag: "/flags/mi.svg",
        abbreviation: "MI",
        population: "10.0 Million (10th)",
        capital: "Lansing",
        area: "96,716 sq mi (11th)",
        motto: "Si quaeris peninsulam amoenam circumspice (If you seek a pleasant peninsula, look about you)",
        nickname: "The Great Lakes State",
        stateFlower: "Apple Blossom",
        stateBird: "American Robin"
    },
    minnesota: {
        name: "Minnesota",
        flag: "/flags/mn.svg",
        abbreviation: "MN",
        population: "5.7 Million (22nd)",
        capital: "Saint Paul",
        area: "86,936 sq mi (12th)",
        motto: "L'Étoile du Nord (The Star of the North)",
        nickname: "The North Star State",
        stateFlower: "Pink and White Lady's Slipper",
        stateBird: "Common Loon"
    },
    mississippi: {
        name: "Mississippi",
        flag: "/flags/ms.svg",
        abbreviation: "MS",
        population: "3.0 Million (35th)",
        capital: "Jackson",
        area: "48,432 sq mi (32nd)",
        motto: "Virtute et armis (By valor and arms)",
        nickname: "The Magnolia State",
        stateFlower: "Magnolia",
        stateBird: "Northern Mockingbird"
    },
    missouri: {
        name: "Missouri",
        flag: "/flags/mo.svg",
        abbreviation: "MO",
        population: "6.2 Million (19th)",
        capital: "Jefferson City",
        area: "69,707 sq mi (21st)",
        motto: "Salus populi suprema lex esto (The welfare of the people shall be the supreme law)",
        nickname: "The Show-Me State",
        stateFlower: "Hawthorn",
        stateBird: "Eastern Bluebird"
    },
    montana: {
        name: "Montana",
        flag: "/flags/mt.svg",
        abbreviation: "MT",
        population: "1.1 Million (43rd)",
        capital: "Helena",
        area: "147,040 sq mi (4th)",
        motto: "Oro y plata (Gold and Silver)",
        nickname: "The Treasure State",
        stateFlower: "Bitterroot",
        stateBird: "Western Meadowlark"
    },
    nebraska: {
        name: "Nebraska",
        flag: "/flags/ne.svg",
        abbreviation: "NE",
        population: "1.9 Million (37th)",
        capital: "Lincoln",
        area: "77,348 sq mi (16th)",
        motto: "Equality before the law",
        nickname: "The Cornhusker State",
        stateFlower: "Goldenrod",
        stateBird: "Western Meadowlark"
    },
    nevada: {
        name: "Nevada",
        flag: "/flags/nv.svg",
        abbreviation: "NV",
        population: "3.2 Million (32nd)",
        capital: "Carson City",
        area: "110,572 sq mi (7th)",
        motto: "All for Our Country",
        nickname: "The Silver State",
        stateFlower: "Sagebrush",
        stateBird: "Mountain Bluebird"
    },
    newHampshire: {
        name: "New Hampshire",
        flag: "/flags/nh.svg",
        abbreviation: "NH",
        population: "1.4 Million (41st)",
        capital: "Concord",
        area: "9,349 sq mi (46th)",
        motto: "Live Free or Die",
        nickname: "The Granite State",
        stateFlower: "Purple Lilac",
        stateBird: "Purple Finch"
    },
    newJersey: {
        name: "New Jersey",
        flag: "/flags/nj.svg",
        abbreviation: "NJ",
        population: "9.3 Million (11th)",
        capital: "Trenton",
        area: "8,723 sq mi (47th)",
        motto: "Liberty and Prosperity",
        nickname: "The Garden State",
        stateFlower: "Violet",
        stateBird: "Eastern Goldfinch"
    },
    newMexico: {
        name: "New Mexico",
        flag: "/flags/nm.svg",
        abbreviation: "NM",
        population: "2.1 Million (36th)",
        capital: "Santa Fe",
        area: "121,590 sq mi (5th)",
        motto: "Crescit eundo (It grows as it goes)",
        nickname: "The Land of Enchantment",
        stateFlower: "Yucca Flower",
        stateBird: "Greater Roadrunner"
    },
    newYork: {
        name: "New York",
        flag: "/flags/ny.svg",
        abbreviation: "NY",
        population: "19.8 Million (4th)",
        capital: "Albany",
        area: "54,555 sq mi (27th)",
        motto: "Excelsior (Ever Upward)",
        nickname: "The Empire State",
        stateFlower: "Rose",
        stateBird: "Eastern Bluebird"
    },
    northCarolina: {
        name: "North Carolina",
        flag: "/flags/nc.svg",
        abbreviation: "NC",
        population: "10.7 Million (9th)",
        capital: "Raleigh",
        area: "53,819 sq mi (28th)",
        motto: "Esse quam videri (To be rather than to seem)",
        nickname: "The Tar Heel State",
        stateFlower: "Dogwood",
        stateBird: "Northern Cardinal"
    },
    northDakota: {
        name: "North Dakota",
        flag: "/flags/nd.svg",
        abbreviation: "ND",
        population: "0.8 Million (47th)",
        capital: "Bismarck",
        area: "70,698 sq mi (19th)",
        motto: "Liberty and union, now and forever, one and inseparable",
        nickname: "The Peace Garden State",
        stateFlower: "Wild Prairie Rose",
        stateBird: "Western Meadowlark"
    },
    ohio: {
        name: "Ohio",
        flag: "/flags/oh.svg",
        abbreviation: "OH",
        population: "11.8 Million (7th)",
        capital: "Columbus",
        area: "44,825 sq mi (34th)",
        motto: "With God, all things are possible",
        nickname: "The Buckeye State",
        stateFlower: "Scarlet Carnation",
        stateBird: "Northern Cardinal"
    },
    oklahoma: {
        name: "Oklahoma",
        flag: "/flags/ok.svg",
        abbreviation: "OK",
        population: "4.0 Million (28th)",
        capital: "Oklahoma City",
        area: "69,899 sq mi (20th)",
        motto: "Labor omnia vincit (Work conquers all)",
        nickname: "The Sooner State",
        stateFlower: "Oklahoma Rose",
        stateBird: "Scissor-tailed Flycatcher"
    },
    oregon: {
        name: "Oregon",
        flag: "/flags/or.svg",
        abbreviation: "OR",
        population: "4.2 Million (27th)",
        capital: "Salem",
        area: "98,381 sq mi (9th)",
        motto: "Alis volat propriis (She flies with her own wings)",
        nickname: "The Beaver State",
        stateFlower: "Oregon Grape",
        stateBird: "Western Meadowlark"
    },
    pennsylvania: {
        name: "Pennsylvania",
        flag: "/flags/pa.svg",
        abbreviation: "PA",
        population: "12.9 Million (5th)",
        capital: "Harrisburg",
        area: "46,054 sq mi (33rd)",
        motto: "Virtue, Liberty, and Independence",
        nickname: "The Keystone State",
        stateFlower: "Mountain Laurel",
        stateBird: "Ruffed Grouse"
    },
    rhodeIsland: {
        name: "Rhode Island",
        flag: "/flags/ri.svg",
        abbreviation: "RI",
        population: "1.1 Million (44th)",
        capital: "Providence",
        area: "1,545 sq mi (50th)",
        motto: "Hope",
        nickname: "The Ocean State",
        stateFlower: "Violet",
        stateBird: "Rhode Island Red"
    },
    southCarolina: {
        name: "South Carolina",
        flag: "/flags/sc.svg",
        abbreviation: "SC",
        population: "5.2 Million (23rd)",
        capital: "Columbia",
        area: "32,020 sq mi (40th)",
        motto: "Dum spiro spero (While I breathe, I hope)",
        nickname: "The Palmetto State",
        stateFlower: "Yellow Jessamine",
        stateBird: "Carolina Wren"
    },
    southDakota: {
        name: "South Dakota",
        flag: "/flags/sd.svg",
        abbreviation: "SD",
        population: "0.9 Million (46th)",
        capital: "Pierre",
        area: "77,116 sq mi (17th)",
        motto: "Under God the people rule",
        nickname: "The Mount Rushmore State",
        stateFlower: "Pasque Flower",
        stateBird: "Ring-necked Pheasant"
    },
    tennessee: {
        name: "Tennessee",
        flag: "/flags/tn.svg",
        abbreviation: "TN",
        population: "7.0 Million (16th)",
        capital: "Nashville",
        area: "42,143 sq mi (36th)",
        motto: "Agriculture and Commerce",
        nickname: "The Volunteer State",
        stateFlower: "Iris",
        stateBird: "Northern Mockingbird"
    },
    texas: {
        name: "Texas",
        flag: "/flags/tx.svg",
        abbreviation: "TX",
        population: "30.0 Million (2nd)",
        capital: "Austin",
        area: "268,596 sq mi (2nd)",
        motto: "Friendship",
        nickname: "The Lone Star State",
        stateFlower: "Bluebonnet",
        stateBird: "Northern Mockingbird"
    },
    utah: {
        name: "Utah",
        flag: "/flags/ut.svg",
        abbreviation: "UT",
        population: "3.4 Million (30th)",
        capital: "Salt Lake City",
        area: "84,899 sq mi (13th)",
        motto: "Industry",
        nickname: "The Beehive State",
        stateFlower: "Sego Lily",
        stateBird: "California Gull"
    },
    vermont: {
        name: "Vermont",
        flag: "/flags/vt.svg",
        abbreviation: "VT",
        population: "0.6 Million (49th)",
        capital: "Montpelier",
        area: "9,616 sq mi (45th)",
        motto: "Freedom and Unity",
        nickname: "The Green Mountain State",
        stateFlower: "Red Clover",
        stateBird: "Hermit Thrush"
    },
    virginia: {
        name: "Virginia",
        flag: "/flags/va.svg",
        abbreviation: "VA",
        population: "8.7 Million (12th)",
        capital: "Richmond",
        area: "42,775 sq mi (35th)",
        motto: "Sic semper tyrannis (Thus always to tyrants)",
        nickname: "The Old Dominion",
        stateFlower: "Dogwood",
        stateBird: "Northern Cardinal"
    },
    washington: {
        name: "Washington",
        flag: "/flags/wa.svg",
        abbreviation: "WA",
        population: "7.8 Million (13th)",
        capital: "Olympia",
        area: "71,362 sq mi (18th)",
        motto: "Al-ki (By and By)",
        nickname: "The Evergreen State",
        stateFlower: "Coast Rhododendron",
        stateBird: "Willow Goldfinch"
    },
    westVirginia: {
        name: "West Virginia",
        flag: "/flags/wv.svg",
        abbreviation: "WV",
        population: "1.8 Million (39th)",
        capital: "Charleston",
        area: "24,230 sq mi (41st)",
        motto: "Montani semper liberi (Mountaineers Are Always Free)",
        nickname: "The Mountain State",
        stateFlower: "Rhododendron",
        stateBird: "Northern Cardinal"
    },
    wisconsin: {
        name: "Wisconsin",
        flag: "/flags/wi.svg",
        abbreviation: "WI",
        population: "5.9 Million (20th)",
        capital: "Madison",
        area: "65,498 sq mi (23rd)",
        motto: "Forward",
        nickname: "The Badger State",
        stateFlower: "Wood Violet",
        stateBird: "American Robin"
    },
    wyoming: {
        name: "Wyoming",
        flag: "/flags/wy.svg",
        abbreviation: "WY",
        population: "0.6 Million (50th)",
        capital: "Cheyenne",
        area: "97,813 sq mi (10th)",
        motto: "Equal Rights",
        nickname: "The Equality State",
        stateFlower: "Indian Paintbrush",
        stateBird: "Western Meadowlark"
    }
};
