import React from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, Link } from 'react-router-dom';
import { stateInfo } from './StateData';
import StateCard from './StateCard';

const App = () => {
    const navigate = useNavigate();

    const generateRandomState = () => {
        const keys = Object.keys(stateInfo);
        const randomKey = keys[Math.floor(Math.random() * keys.length)];
        navigate(`/${randomKey}/`);
    };

    return (
      <div>
        <nav className="navbar navbar-light bg-light">
              <div className="container-fluid">
                  <Link className="navbar-brand" to="/">Random State</Link>
              </div>
          </nav>
        <div className="container text-center mt-5">
          
            <Routes>
                <Route
                    path="/"
                    element={
                      <div>
                      <h1 className="mb-4">Random US State Generator</h1>
                        <button onClick={generateRandomState} className="btn btn-primary mb-4">Random State</button>
                        <div className="description text-muted mb-4">
                            <p>Random US State Generator helps you explore all 50 states of America, one random state at a time!</p>
                            <p>Simply click the button above to discover a random US state.</p>
                            <p>You'll be directed to a random state's page, where you can learn about its flag, history, and other interesting facts.</p>
                            <p>Ready to explore? Hit the button and start your journey now!</p>
                        </div>
                      </div>
                    }
                />
                {Object.keys(stateInfo).map((stateKey) => (
                  
                    <Route
                        key={stateKey}
                        path={`/${stateKey}`}
                        element={
                          <div>
                            <h1 className="mb-4">{stateInfo[stateKey].name}</h1>
                            <button onClick={generateRandomState} className="btn btn-primary mb-4">Random State</button>
                            <StateCard state={stateInfo[stateKey]} />
                          </div>
                      }
                    />
                ))}
            </Routes>
        </div>
        </div>
    );
};

const AppWrapper = () => {
    return (
        <Router>
            <App />
        </Router>
    );
};

export default AppWrapper;
